import { getParametriMovimenti, getProfilo, getSaldoComplessivo, parametriMovimenti, profilo, saldoComplessivo, salvaWidgets, setBlurText, widgetSaldo, widgetsUtente } from "@sparkasse/commons";
import BankIcon from "component/Icon/icon.component";
import Psd2Enrollment from "component/psd2/psd2Enrollment.component";
import SelectRapportoMini, { IRapporto } from "component/SelectRapportoMini/SelectRapportoMini";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import info from "../../assets/icons/help/help_home.svg";
import { currencyFormat, formatRapporto } from "../../helpers/utilities";
import { AppState } from "../../reducers";

const Balance: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  const {
    blurText,
    saldoComplessivo,
    rapportiNoPSD2,
    widgetSaldo,
    widgetsUtente,
    profilo,
    setBlurText,
    salvaWidgets,
    getProfilo,
    getParametriMovimenti,
    getSaldoComplessivo
  } = props;

  const onHideText = () => {
    setBlurText(!blurText);
  };

  const items: Array<IRapporto> = [{
    id: "0",
    description: `${profilo?.datiUtente?.datiPersonali?.descrizione || ""} - ${t('general.carousel.tuttiIContri')}`
  }].concat(rapportiNoPSD2.rapporti?.map(r => ({
    id: (r.rapporto?.iban || "-1").toString(),
    description: formatRapporto(r)
  })) || []);

  // se un solo rapporto inutile vedere "tutti i conti"
  const rapportoSelezionatoDefault = items.length === 2 ? items[1].id : items[0].id;

  const selezionaConto = (item: IRapporto) => {
    const _widgetSaldo = widgetSaldo ? [{
      ...widgetSaldo, option: {
        toggle: widgetSaldo.option?.toggle,
        interval: widgetSaldo.option?.interval,
        codicerapporto: item.id,
      }
    }] : [];
    salvaWidgets((widgetsUtente?.widgets || []).concat(_widgetSaldo), true);
  };

  const contoSelezionatoCompleto = rapportiNoPSD2?.rapporti?.find(r => r.rapporto?.iban === widgetSaldo?.option?.codicerapporto);
  const saldoContabile = contoSelezionatoCompleto ? contoSelezionatoCompleto?.saldo?.saldoContabile : saldoComplessivo?.saldoContabile;
  const saldoDisponibile = contoSelezionatoCompleto ? contoSelezionatoCompleto?.saldo?.saldoDisponibile : saldoComplessivo?.saldoDisponibile;
  const divisa = contoSelezionatoCompleto?.rapporto?.divisa || saldoComplessivo?.divisa;

  const renderTooltip = (txt: string) => {
    return (
      <Tooltip id="button-tooltip">
        {txt}
      </Tooltip>
    );
  };

  const enrollmentSuccessCallback = () => {
    getParametriMovimenti(true);
    getSaldoComplessivo();
  };

  return (
    <div className="balance-home">
      <span className="corner cornen-saldo-home"></span>

      <SelectRapportoMini
        items={items}
        selectedItem={contoSelezionatoCompleto?.rapporto?.iban || rapportoSelezionatoDefault}
        onSelect={selezionaConto}
        forceShowSelected
      />

      <Psd2Enrollment okCallback={enrollmentSuccessCallback} />

      <div className="complessivo complessivo-contabile">

        <span className="txtCaptionComplessivo">
          {t('home.balance.saldoContabile')}
        </span>

        <div className="viewBalance">

          <span
            className={`saldo-complessivo-value is-saldo ${saldoContabile != null ? Math.sign(saldoContabile) === -1 ? 'saldo-negative-color' : 'saldo-positive-color' : ''}`}
          >
            {currencyFormat(saldoContabile)}
          </span>

          <span className="currency">
            {divisa}
          </span>

          <div className="mostra-nascondi-saldo">
            {blurText
              ? <BankIcon title="occhio_occhio_aperto" red className="img-hide-amounts-inline" tooltip={t('home.balance.mostraImporti')} onClick={() => { onHideText(); }} />
              : <BankIcon title="occhio_occhio_chiuso" red className="img-hide-amounts-inline" tooltip={t('home.balance.nascondiImporti')} onClick={() => { onHideText(); }} />
            }
          </div>

        </div>

      </div>

      <div className="complessivo complessivo-disponibile">

        <span className="txtCaptionDisponibile">
          {t('home.balance.saldoDisponibile')}
        </span>

        <div className="viewBalance">

          <span
            className={`saldo-disponibile-value is-saldo ${saldoDisponibile != null ? Math.sign(saldoDisponibile) === -1 ? 'saldo-negative-color' : 'saldo-positive-color' : ''}`}
          >
            {currencyFormat(saldoDisponibile)}
          </span>

          <span className="currency">
            {divisa}
          </span>

          <span className="tooltip-help-home">
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(t("home.balanceTooltip"))}
            >
              <img className="info-tooltip-icon" src={info} />
            </OverlayTrigger>
          </span>

        </div>

      </div>

      {!!saldoComplessivo?.alertPsd2?.length &&
        <div className="widget-errore-psd2">
          <div className="widget-errore-psd2-icon">
            <BankIcon title="alert" keepSize />
          </div>
          <div className="widget-errore-psd2-testo">
            <span>{t('psd2Attiva.erroreSaldo')}</span>
          </div>
        </div>
      }

    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  blurText: state.general.blurText,
  saldoComplessivo: saldoComplessivo(state.movimenti),
  rapportiNoPSD2: parametriMovimenti(state.movimenti),
  widgetSaldo: widgetSaldo(state.widgets),
  widgetsUtente: widgetsUtente(state.widgets),
  profilo: profilo(state.profilo),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      setBlurText,
      salvaWidgets,
      getProfilo,
      getParametriMovimenti,
      getSaldoComplessivo
    },
    dispatch
  );

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Balance);
