import { browserSupported, comunicazioniMessaggi, datiUtente, elencoAppuntamenti, getComunicazioniMessaggi, getElencoAppuntamenti, getNotificaTributiLocali, getOperazioniOneclickHomepage, getOperazioniPreferiti, getParametriMovimenti, getParametriProfilo, getParametriWidget, getSaldoComplessivo, getWidgetsUtente, menuList, NotificaTributiDto, notificheTributiLocali, OperazioniOneClickDto, operazioniOneclickHomepage, operazioniPreferiteCorrenti, profilo, profiloUltimoAccesso, segnaComeLettoMessaggi, selectedEnv, setStepHomeBonifico, setTributiLocaliSetDettaglio, setWebMenu, virtualBranchEnabled } from "@sparkasse/commons";
import modificaSmall from "assets/icons/azioni/modifica_small.svg";
import { ComponenteComunicazione } from "component/comunicazioniUtente/comunicazioniUtente.component";
import { WEBPAGE } from "component/history/history.component";
import ModalPersonalizzaHomepageComponent from "component/modalPersonalizzaHomepage/modalPersonalizzaHomepage.component";
import RightbarShoulder from "component/Rightbar/RightbarShoulder";
import WidgetBilancio from "component/widgetBilancio/widgetBilancio.component";
import WidgetConsulenzaVirtuale from "component/widgetConsulenzaVirtuale/widgetConsulenzaVirtuale.component";
import WidgetDossierTitoli from "component/widgetDossierTitoli/widgetDossierTitoli.component";
import WidgetMutui from "component/widgetMutui/widgetMutui.component";
import WidgetPatrimonio from "component/widgetPatrimonio/widgetPatrimonio.component";
import WidgetPrestiti from "component/widgetPrestiti/widgetPrestiti.component";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import version from "version";
import Banner from "../../component/banner/banner.component";
import SnackBarAppuntamenti from "../../component/snackBarAppuntamenti/snackBarAppuntamenti.component";
import { AppState } from "../../reducers";
import Balance from "./home.balance";
import Movements from "./home.movements";
import Oneclick from "./home.oneclick";
import OperazioniPreferite from "./home.operazioniPreferite";
import TributiLocali from "./home.tributiLocali";


export const WidgetMapping: { [key: string]: JSX.Element } = {
  "menu.prestito_green": <WidgetPrestiti />,
  "menu.mutui": <WidgetMutui />,
  "menu.portafoglio_titoli": <WidgetDossierTitoli />,
  "menu.bilancio": <WidgetBilancio />,
  "menu.riepilogo_patrimonio": <WidgetPatrimonio />,
  "menu.saldo_e_movimenti": <Movements />,
  "consulenzaVirtuale": <WidgetConsulenzaVirtuale />
};

const SpallaDestraContent = (
  {
    operazioni,
    notificheTributiLocali,
    setTributiLocaliSetDettaglio,
    tributiLocaliAbilitati
  }: {
    operazioni: OperazioniOneClickDto
    notificheTributiLocali: NotificaTributiDto,
    setTributiLocaliSetDettaglio: Function,
    tributiLocaliAbilitati: boolean
  }
) => {
  return <>
    <Oneclick
      operazioni={operazioni}
      maxOperazioniToShow={notificheTributiLocali.tipoNotifica !== 'EMPTY' ? 3 : 5}
    />

    {tributiLocaliAbilitati && notificheTributiLocali && notificheTributiLocali.tipoNotifica !== 'EMPTY' && (
      <TributiLocali
        notificheTributi={notificheTributiLocali}
        setDettaglioTributo={setTributiLocaliSetDettaglio}
      />
    )}
  </>;
};

const Home: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  const {
    menuList,
    history,
    blurText,
    comunicazioniMessaggi,
    segnaComeLettoMessaggi,
    widgets,
    notificheTributiLocali,
    getSaldoComplessivo,
    getParametriMovimenti,
    getParametriWidget,
    getComunicazioniMessaggi,
    getParametriProfilo,
    getNotificaTributiLocali,
    getWidgetsUtente,
    setTributiLocaliSetDettaglio,
    operazioniPreferiteCorrenti,
    getOperazioniPreferiti,
    setStepHomeBonifico,
    setWebMenu,
    operazioni,
    getOperazioniOneclickHomepage,
    datiUtente,
    getElencoAppuntamenti,
    elencoAppuntamenti,
    ultimoAccesso,
    profilo,
    virtualBranchEnabled
  } = props;

  const [spallaDestraVisible, setSpallaDestraVisible] = useState(false);
  const [modalPersonalizzHomepageVisible, setModalPersonalizzHomepageVisible] = useState(false);

  useEffect(() => {

    getSaldoComplessivo(true);
    getParametriMovimenti(true);
    getParametriWidget();
    getComunicazioniMessaggi();
    getParametriProfilo();
    getOperazioniOneclickHomepage();

    !(widgets.widgetsUtente && widgets.widgetsUtente.widgets) && getWidgetsUtente();

    document.getElementById("main-switch")?.firstElementChild?.scrollTo({ top: 0 });

  }, []);

  const goTo = (path: string) => {
    setStepHomeBonifico(1);
    setWebMenu(false);
    history.push(path);
  };

  const tributiLocaliAbiltati = useMemo(() => {
    return !!menuList.profiloMenu?.items?.find(s =>
      s.id === "menu.pagare_e_trasferire")?.submenu?.find(s =>
        s.id === "menu.tasse_e_tributi")?.submenu?.find(s =>
          s.id === "menu.tributi_locali");
  }, [menuList.profiloMenu?.items]);

  useEffect(() => {
    tributiLocaliAbiltati && getNotificaTributiLocali();
  }, [tributiLocaliAbiltati]);

  useEffect(() => {
    if (!!datiUtente?.ndg && virtualBranchEnabled) {
      getElencoAppuntamenti();
    }
  }, [datiUtente]);

  useEffect(() => {
    if (ultimoAccesso && (ultimoAccesso.data && ultimoAccesso.ora && ultimoAccesso.canale)) {
      sessionStorage.setItem("ultimoAccessoData", ultimoAccesso.data);
      sessionStorage.setItem("ultimoAccessoOra", ultimoAccesso.ora);
      sessionStorage.setItem("ultimoAccessoCanale", ultimoAccesso.canale);
    }
  }, [profilo]);

  return (
    <div className={`wide${blurText ? ' nascondi-saldo' : ''}`}>
      <div className="main">
        <div className="core">

          {!!sessionStorage.ultimoAccessoData && !!sessionStorage.ultimoAccessoOra && !!sessionStorage.ultimoAccessoCanale &&
            <div className="ultimo-accesso">
              <span>
                {t('homepage.ultimoAccesso.data.ultimoAccesso')} <b>{sessionStorage.ultimoAccessoData}</b> {t('homepage.ultimoAccesso.data.alle')} <b>{sessionStorage.ultimoAccessoOra}</b> {t('homepage.ultimoAccesso.data.da')} {sessionStorage.ultimoAccessoCanale}
              </span>
            </div>}

          <OperazioniPreferite
            goTo={goTo}
            operazioniPreferiteCorrenti={operazioniPreferiteCorrenti}
            getOperazioniPreferiti={getOperazioniPreferiti}
            onOpenSpallaDestra={() => setSpallaDestraVisible(true)}
          />
          {elencoAppuntamenti?.length > 0 && (
            <SnackBarAppuntamenti />
          )}

          <div className="home-top-section">

            <div className="home-balance-and-widget">
              <Balance />

              <div className="widget-wrapper">
                {widgets.widgetsUtente?.widgets?.length
                  ? WidgetMapping[widgets.widgetsUtente?.widgets?.[0]?.widgetId || WEBPAGE.SaldoEMovimenti.menu]
                  : null
                }
              </div>
            </div>

            <aside className="spalla-destra-inside-core" aria-label={t("accessibilita.pannelloLaterale")}>
              <SpallaDestraContent
                operazioni={operazioni}
                notificheTributiLocali={notificheTributiLocali}
                setTributiLocaliSetDettaglio={setTributiLocaliSetDettaglio}
                tributiLocaliAbilitati={tributiLocaliAbiltati}
              />
            </aside>

          </div>

          <Banner
            funzionalita="HOMEPAGE"
          />

          <div className="widgets-grid-container">
            <div className="widgets-grid">
              {widgets.widgetsUtente?.widgets?.
                sort((a, b) => ((a.order || 0) <= (b.order || 0) ? -1 : 1)).
                filter((w, i) => (i > 0 && !!w.widgetId && WidgetMapping[w.widgetId])).
                map((w, i) =>
                  <div
                    key={`widget-${i}-${w.widgetId}`}
                    className={"widget-wrapper"}
                  >
                    {!!w.widgetId && WidgetMapping[w.widgetId]}
                  </div>
                )
              }
            </div>
            <div className="btn-edit-widgets">
              <button
                onClick={() => {
                  setModalPersonalizzHomepageVisible(true);
                }}
              >
                {t("personalizzaHomepage.personalizzaHomepage")}
                <img src={modificaSmall} />
              </button>
            </div>
          </div>

          <div className="footer">
            {!props.browserSupported && <p className="footer-text mb-3">{t("home.browserNonSupportato")}</p>}
            <p className="footer-text">{t("home.footer")}</p>
            {version && (
              <span className="text-right" style={{ fontSize: 7 }}>{selectedEnv} - {moment(version).format('DD/MM/YYYY HH:mm')}</span>
            )}
          </div>

        </div>
      </div>

      <ComponenteComunicazione
        comunicazioniMessaggi={comunicazioniMessaggi}
        segnaComeLettoMessaggi={segnaComeLettoMessaggi}
      />

      {
        !!modalPersonalizzHomepageVisible &&
        <ModalPersonalizzaHomepageComponent
          show={modalPersonalizzHomepageVisible}
          onClose={() => { setModalPersonalizzHomepageVisible(false) }}
        />
      }

      <RightbarShoulder
        isVisible={spallaDestraVisible}
        setVisible={setSpallaDestraVisible}
      >
        <>
          <SpallaDestraContent
            operazioni={operazioni}
            notificheTributiLocali={notificheTributiLocali}
            setTributiLocaliSetDettaglio={setTributiLocaliSetDettaglio}
            tributiLocaliAbilitati={tributiLocaliAbiltati}
          />

          <h6
            className="text-one-click"
            tabIndex={0}
            aria-label={t('button.azioniRapide')}
          >
            {t('button.azioniRapide')}
          </h6>

          <OperazioniPreferite
            goTo={goTo}
            operazioniPreferiteCorrenti={operazioniPreferiteCorrenti}
            getOperazioniPreferiti={getOperazioniPreferiti}
          />

        </>
      </RightbarShoulder>

    </div >
  );
};

const mapStateToProps = (state: AppState) => ({
  blurText: state.general.blurText,
  widgets: state.widgets,
  comunicazioniMessaggi: comunicazioniMessaggi(state.messaggi),
  notificheTributiLocali: notificheTributiLocali(state.tributiLocali),
  operazioniPreferiteCorrenti: operazioniPreferiteCorrenti(state.operazioni),
  operazioni: operazioniOneclickHomepage(state.oneclick),
  browserSupported: browserSupported(state.postlogin),
  menuList: menuList(state.menu),
  elencoAppuntamenti: elencoAppuntamenti(state.collaboration),
  datiUtente: datiUtente(state.profilo),
  ultimoAccesso: profiloUltimoAccesso(state.profilo),
  profilo: profilo(state.profilo),
  virtualBranchEnabled: virtualBranchEnabled(state.prelogin)
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getSaldoComplessivo,
      getParametriWidget,
      getWidgetsUtente,
      getComunicazioniMessaggi,
      segnaComeLettoMessaggi,
      getParametriProfilo,
      getNotificaTributiLocali,
      setTributiLocaliSetDettaglio,
      getParametriMovimenti,
      setStepHomeBonifico,
      setWebMenu,
      getOperazioniPreferiti,
      getOperazioniOneclickHomepage,
      getElencoAppuntamenti,
    },
    dispatch
  );

interface RouteParams {
  id: string
}

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<RouteParams>;

export default connect(mapStateToProps, mapDispatchToProps)(Home);
