import React from "react";

interface Props {
  descrizione: string
  onClick: any
  disable?: boolean,
  filled?: boolean
}

const ButtonPrimary: React.FC<Props> = (props: Props) => {

  const {
    onClick,
    descrizione,
    disable,
    filled
  } = props;

  return (
    <button
      type="button"
      className={`${filled ? "filled" : "empty"}_primary_button`}
      onClick={disable ? undefined : onClick}
      disabled={disable}
    >
      {descrizione}
    </button>
  );
};

export default ButtonPrimary;
