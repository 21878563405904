import { consulente, datiFiliale, getConsulente, getDatiFiliale, profilo } from "@sparkasse/commons";
import ButtonText2 from "component/buttonText2/buttonText2.component";
import { navigateToPage, WEBPAGE } from "component/history/history.component";
import BankIcon from "component/Icon/icon.component";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../reducers";

export const WIDGET_CONSULENZA_VIRTUALE = "consulenzaVirtuale";

const navigateToNuovoAppuntamento = () => {
  navigateToPage(WEBPAGE.NuovoAppuntamento.url);
};

const navigateToElencoAppuntamenti = () => {
  navigateToPage(WEBPAGE.ElencoAppuntamenti.url);
};

const WidgetConsulenzaVirtuale = (props: Props) => {

  const { t } = useTranslation();

  const {
    profilo,
    getConsulente,
    consulente,
    datiFiliale,
    getDatiFiliale
  } = props;

  useEffect(() => {
    if (!consulente && profilo?.datiUtente) {
      getConsulente();
    }
    if (consulente && consulente.type === 'VIRTUAL') {
      getDatiFiliale();
    }
  }, [profilo, consulente]);

  return (
    <div className="widget widget-bilancio">

      <p className="widget-title">
        {t("home.consulenzaVirtuale.title")}
      </p>

      <div className="underline-short" />

      {!!consulente && consulente.type === 'PHYSICAL' && (
        <div className="chartView-widget-bilancio">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ backgroundColor: '#F6F6F6', borderRadius: 24, width: 48, height: 48, marginRight: 12 }}>
              <BankIcon title='menu.la_tua_banca' width={36} height={36} style={{ marginTop: 6, marginLeft: 6 }} />
            </div>
            <div style={{ flexDirection: 'column', marginTop: 8 }}>
              <p style={{ fontSize: 20, fontWeight: 'bold' }}>{consulente?.displayName}</p>
              <p style={{ fontSize: 20 }}>{consulente?.phone}</p>
              <p style={{ fontSize: 20 }}>{consulente?.email}</p>
            </div>
          </div>
        </div>
      )}

      {!!consulente && !!datiFiliale && consulente.type === 'VIRTUAL' && (
        <div className="chartView-widget-bilancio">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ backgroundColor: '#F6F6F6', borderRadius: 24, width: 48, height: 48, marginRight: 12 }}>
              <BankIcon title='menu.la_tua_banca' width={36} height={36} style={{ marginTop: 6, marginLeft: 6 }} />
            </div>
            <div style={{ flexDirection: 'column', marginTop: 8 }}>
              <p style={{ fontSize: 20, fontWeight: 'bold' }}>{datiFiliale?.filiale?.nomeFiliale}</p>
              {datiFiliale.filiale?.telefonoF1 && <p style={{ fontSize: 20 }}>{datiFiliale.filiale?.telefonoF1}</p>}
              {datiFiliale.filiale?.emailFiliale && <p style={{ fontSize: 20 }}>{datiFiliale.filiale?.emailFiliale}</p>}
            </div>
          </div>
        </div>
      )}

      <ButtonText2
        className="widget-vai-a-dettaglio"
        onClick={navigateToNuovoAppuntamento}
        img="navigazione_avanti"
        descrizione={t("home.consulenzaVirtuale.prenotaUnAppuntamento")}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  profilo: profilo(state.profilo),
  consulente: consulente(state.collaboration),
  datiFiliale: datiFiliale(state.datiFiliale)
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
  getConsulente,
  getDatiFiliale
}, dispatch);

export type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(WidgetConsulenzaVirtuale);
