import BankIcon from "component/Icon/icon.component";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export interface IRapporto {
  id: string;
  description: string;
  descriptionClosed?: string;
}

interface Props {
  items: Array<IRapporto>;
  onSelect: (item: IRapporto) => void;
  selectedItem?: string;
  numeroMinimoItems?: number;
  forceShowSelected?: boolean;
  tooltipText?: string;
}

const SelectRapportoMini = (props: Props) => {
  const {
    items,
    onSelect,
    selectedItem,
    numeroMinimoItems,
    forceShowSelected,
    tooltipText,
  } = props;

  const { t } = useTranslation();

  const optionContainer = useRef<HTMLDivElement | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedElementIndex, selectElementIndex] = useState(-1);
  const [focusedIndex, setFocusedIndex] = useState(-1); // Indice dell'elemento attualmente focalizzato
  const numeroDiElementiSufficiente = items.length >= (numeroMinimoItems || 3);

  useEffect(() => {
    const _selectedElementIndex = items?.findIndex((r) => r.id === selectedItem);
    selectElementIndex(_selectedElementIndex);
    setFocusedIndex(_selectedElementIndex); // Imposta l'elemento selezionato come attivo
  }, [items, selectedItem]);

  const removeClickListener = () => {
    document.removeEventListener("click", outsideClickOptionsList);
  };

  const outsideClickOptionsList = (event: any) => {
    if (!optionContainer.current?.contains(event.target)) {
      removeClickListener();
      setIsDropdownOpen(false);
    }
  };

  const openDropdown = (open: boolean) => {
    if (open && numeroDiElementiSufficiente) {
      setIsDropdownOpen(true);
      setTimeout(() => {
        document.addEventListener("click", outsideClickOptionsList);
      });
    } else {
      setIsDropdownOpen(false);
      removeClickListener();
    }
  };

  return !!forceShowSelected || numeroDiElementiSufficiente ? (
    <div className="mini-seleziona-rapporto">
      <div
        className={`mini-seleziona-rapporto-clickable 
          ${numeroDiElementiSufficiente ?
            "numero-elementi-sufficiente"
            : ""}`}
        onClick={() => openDropdown(!isDropdownOpen)}
        aria-haspopup="listbox"
        aria-expanded={isDropdownOpen}
        aria-label={t("accessibilita.selectRapportoMini.selezionaOpzione")}
        tabIndex={0}
        aria-activedescendant={
          focusedIndex >= 0 ? `rapporto-mini-${focusedIndex}` : undefined
        }
        role="combobox"
        aria-controls="listbox-rapporto"
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            openDropdown(!isDropdownOpen);
          }
        }}
      >
        <label className="mini-seleziona-rapporto-selezione" id="rapporto-label">
          {items?.[selectedElementIndex]?.descriptionClosed ||
            items?.[selectedElementIndex]?.description ||
            "-"}
        </label>
        <BankIcon title="azioni_mini_esegui_mini" rotation={90} width={33} height={33} />
      </div>

      {isDropdownOpen && numeroDiElementiSufficiente && (
        <div
          className="mini-seleziona-rapporto-dropdown"
          ref={optionContainer}
          id="listbox-rapporto"
          role="listbox"
        >
          {items.map((item, i) => (
            <div
              aria-label={`${item.description} ${item.id === items?.[selectedElementIndex]?.id
                ? t('accessibilita.selectRapporto.optionSelezionato')
                : t('accessibilita.selectRapporto.optionNonSelezionato')}`}
              id={`rapporto-mini-${i}`}
              key={`rapporto-mini-${i}`}
              className={`mini-seleziona-rapporto-dropdown-item ${item.id === items?.[selectedElementIndex]?.id ? "row-alternative-color" : ""}`}

              role="option"
              aria-selected={item.id === items?.[selectedElementIndex]?.id}
              onClick={() => {
                if (item.id !== items?.[selectedElementIndex]?.id) {
                  selectElementIndex(i);
                  onSelect(item);
                }
                openDropdown(false);
              }}
            >
              {item.description}
            </div>
          ))}
        </div>
      )}
      {tooltipText && (
        <BankIcon
          title="info.tooltip"
          width={33}
          height={33}
          style={{ marginLeft: 8 }}
          tooltip={tooltipText}
        />
      )}
    </div>
  ) : null;
};

export default SelectRapportoMini;
