import BankIcon from "component/Icon/icon.component";
import React from "react";
import Switch from "react-switch";
import Colors from "styles/generated/colors";

interface Props {
  isToggle?: boolean
  disabled?: boolean
  formField?: boolean
  descrizione?: string | React.ReactFragment
  value: boolean
  onChange?: (value: boolean) => void
  textStyle?: string
}

const InputBox: React.FC<Props> = (props: Props) => {

  const {
    isToggle,
    disabled,
    formField,
    descrizione,
    value,
    textStyle,
    onChange
  } = props;

  const setClick = () => {
    !disabled && onChange && onChange(!value);
  };

  const toggle = (
    <label className={`switch-container`}>
      <Switch
        onChange={setClick}
        checked={!!value}
        onColor={Colors.primary}
        offColor={Colors.mediumgrey}
        onHandleColor={Colors.white}
        uncheckedIcon={false}
        checkedIcon={false}
        className="react-switch"
        id="material-switch"
        height={20}
        width={40}
      />
      {descrizione &&
        <span className="descrizione-label" >{descrizione}</span>
      }
    </label>
  );

  const checkbox = (
    <>
      <button
        className={disabled ? 'input-box-disabled' : value ? 'input-box-checked' : 'input-box-enabled'}
        onClick={setClick}
      >
        <BankIcon
          title="visto_checkbox"
          className={`visto-checkbox${!value ? ' d-none' : ''}`}
          keepSize
        />
      </button>
      {descrizione &&
        <label
          className={`input-box-label${disabled ? ' input-box-label-disabled' : ''} ${textStyle}`}
          onClick={disabled ? undefined : () => setClick()}
        >
          {descrizione}
        </label>
      }
    </>
  );

  return (
    <div className={`input-box flex${formField ? ' input-box-form-field' : ''}`}>
      {isToggle ? toggle : checkbox}
    </div>
  );
};

export default InputBox;
