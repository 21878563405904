import { ContattoDto, ContattoEmailDto, ContattoIbanDto, ContattoMezzoDto, ContattoSudtirolPasDto, ContattoTelefonoDto, setBlurText } from "@sparkasse/commons";
import corner from "assets/icons/random/mirino.svg";
import beneficiarioNonAttendibileIcon from "assets/icons/rubrica/beneficiario-non-attendibile.svg";
import { iAdditionalInfo } from "component/cards/card.component";
import BankIcon from "component/Icon/icon.component";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AppState } from "reducers";
import { AnyAction, bindActionCreators, Dispatch } from "redux";

const RubricaCard: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  const {
    descrizione,
    altoAdigePass,
    ibans,
    targhe,
    apriDettaglio,
    item,
    ctaVerticali,
    callbackElimina,
    callbackModifica,
    mostraDettagli,
    telefono,
    cf,
    piva,
    email
  } = props;

  const [isDeleteItem, setDeleteItem] = useState<boolean>(false);

  const getEmail = (email: ContattoEmailDto[]) => {
    //console.log(email.length)
    const emailMapped = email.map((e, index) => (email.length !== index + 1) ? e.indirizzo + ", " : e.indirizzo + " ");
    return emailMapped.toString().length > 75 ? emailMapped.toString().substring(0, 75).concat('...') : emailMapped;
  };

  const actionElimina = () => {
    setDeleteItem(!isDeleteItem);
    callbackElimina && callbackElimina(item);
  };

  const onActionModifica = () => {
    callbackModifica && callbackModifica(item);
  };

  return (
    <div className="rubrica">
      <div className="card-bozze-wrapper-margin-bottom">
        <div className="card-bozze-viewer">
          <div className={`card-view-container`}>
            <img className="card-view-container-corner" src={corner} />
            <div
              className={`card-view card-view-${mostraDettagli ? "mostraDettagli" : "home"
                }`}
            >
              <div className="badge-width">
                <img src={beneficiarioNonAttendibileIcon} />
              </div>
              <div className="detail-width pointer margin-card" onClick={() => apriDettaglio()}>
                <div className="card-contact-view--body-view--full-row">
                  <div className="card-view--body-view">
                    <span className="card-contact-view--body-view--description">
                      {descrizione || "-"}
                    </span>
                  </div>
                </div>
                {!!email?.length && <div className="card-contact-view--body-view--full-row">
                  <div className="card-view--body-view">
                    <span className="card-contact-view--body-view--description" style={{ fontWeight: 'normal', color: '#9d9d9d' }}>
                      {getEmail(email) || "-"}
                    </span>
                  </div>
                </div>}
                <div className="card-view-contact--body-view--full-row">

                  {!!ibans?.length && (
                    <div
                      key={ibans[0].ibanAlias}
                      className="card-contact-view--body-view--iban"
                    >
                      <span className="card-contact-view--description-icon vertical-center">
                        <BankIcon width={32} height={32} title={ibans[0].dispensaCredenziale ? 'fai-bonifico-trusted' : 'fai-bonifico'} />
                      </span>
                      <div className="flex-1">
                        <div className="card-view--body-view--additional-info-value-rubrica sparkasse-grey" style={{}}>
                          <span className="sparkasse-grey">
                            {ibans[0].etichetta ? ibans[0].etichetta : t('rubrica.nuovoContatto.step1.iban')}
                          </span>
                          <span className="font-size-13 mt-1 sparkasse-grey">
                            {`1/${ibans.length}`}
                          </span>
                        </div>
                        <span className="card-view--body-view--additional-info-value-rubrica">
                          {ibans[0].ibanFormattato}
                        </span>
                      </div>
                    </div>
                  )}

                  {!!telefono?.length && (
                    <div
                      className="card-contact-view--body-view--telefono"
                      key={telefono[0].etichetta}
                    >
                      <span className="card-contact-view--description-icon vertical-center">
                        <BankIcon width={31} height={32} title={telefono[0].dispensaCredenziale ? 'ricarica-cellulare-trusted' : 'ricarica-cellulare'} />
                      </span>
                      <div className="flex-column d-flex flex-1">
                        <div className="card-view--body-view--additional-info-value-rubrica sparkasse-grey" style={{}}>
                          <span className="sparkasse-grey">
                            {telefono[0].etichetta ? telefono[0].etichetta : t('rubrica.nuovoContatto.step1.numeroDiTelefono')}
                          </span>
                          <span className="font-size-13 mt-1 sparkasse-grey">
                            {`1/${telefono.length}`}
                          </span>
                        </div>
                        <span className="card-view--body-view--additional-info-value-rubrica">
                          {telefono[0].numero}
                        </span>
                      </div>
                    </div>
                  )}
                  {cf && cf.cf && (
                    <div
                      className="card-contact-view--body-view--iban"
                    >
                      <span className="card-contact-view--description-icon vertical-center">
                        <BankIcon width={32} height={32} title={cf.dispensaCredenziale ? 'paga-f24-trusted' : 'paga-f24'} />
                      </span>
                      <div className="flex-1">
                        <div className="card-view--body-view--additional-info-value-rubrica sparkasse-grey" style={{}}>
                          <span className="sparkasse-grey">
                            {t('rubrica.nuovoContatto.step1.codiceFiscale')}
                          </span>
                        </div>
                        <span className="card-view--body-view--additional-info-value-rubrica">
                          {cf.cf}
                        </span>
                      </div>
                    </div>
                  )}

                  {piva && piva.piva && (
                    <div
                      className="card-contact-view--body-view--telefono"
                    >
                      <span className="card-contact-view--description-icon vertical-center">
                        <BankIcon width={31} height={32} title={piva.dispensaCredenziale ? 'paga-f24-trusted' : 'paga-f24'} />
                      </span>
                      <div className="flex-1">
                        <div className="card-view--body-view--additional-info-value-rubrica sparkasse-grey " style={{}}>
                          <span className="sparkasse-grey">
                            {t('rubrica.nuovoContatto.step1.partitaIVA')}
                          </span>
                        </div>
                        <span className="card-view--body-view--additional-info-value-rubrica">
                          {piva.piva}
                        </span>
                      </div>
                    </div>
                  )}
                  {!!altoAdigePass?.length && (
                    <div
                      key={altoAdigePass[0].pan}
                      className="card-contact-view--body-view--iban "
                    >
                      <span className="card-contact-view--description-icon vertical-center">
                        <BankIcon width={32} height={32} title={altoAdigePass[0].dispensaCredenziale ? 'paga-alto-adige-pass-trusted' : 'paga-alto-adige-pass'} />
                      </span>
                      <div className="flex-column d-flex flex-1">
                        <div className="card-view--body-view--additional-info-value-rubrica sparkasse-grey d-flex justify-space-between" style={{}}>
                          <span className="sparkasse-grey">
                            {altoAdigePass[0].etichetta ? altoAdigePass[0].etichetta : t('rubrica.nuovoContatto.step1.altoAdigePass')}
                          </span>
                          <span className="font-size-13 mt-1 sparkasse-grey ">
                            {`1/${altoAdigePass.length}`}
                          </span>
                        </div>
                        <span className="card-view--body-view--additional-info-value-rubrica">
                          {altoAdigePass[0].pan}
                        </span>
                      </div>
                    </div>
                  )}
                  {!!targhe?.length && (
                    <div
                      className="card-contact-view--body-view--telefono"
                      key={targhe[0].etichetta}
                    >
                      <span className="card-contact-view--description-icon vertical-center">
                        <BankIcon width={31} height={32} title={targhe[0].dispensaCredenziale ? 'paga-bollo-trusted' : 'paga-bollo'} />
                      </span>
                      <div className="flex-column d-flex flex-1">
                        <div className="card-view--body-view--additional-info-value-rubrica sparkasse-grey" style={{}}>
                          <span className="sparkasse-grey">
                            {targhe[0].etichetta ? targhe[0].etichetta : t('rubrica.nuovoContatto.step1.targa')}
                          </span>
                          <span className="font-size-13 mt-1 sparkasse-grey">
                            {`1/${targhe.length}`}
                          </span>
                        </div>
                        <span className="card-view--body-view--additional-info-value-rubrica">
                          {targhe[0].targa}
                        </span>
                      </div>
                    </div>
                  )}

                </div>
              </div>
              {ctaVerticali && (
                <div className="card-view--body-view card-contact-view--body-view--vertical-cta card-view-container">
                  <div className="vertical-cta-items">
                    <div
                      className={`vertical-cta-item ${!callbackModifica && "vertical-cta-item-disabled"
                        }`}
                      onClick={onActionModifica}
                    >
                      <BankIcon title="cta.modifica" height={32} width={32} />
                    </div>
                    <div
                      className={`vertical-cta-item ${!callbackElimina && "vertical-cta-item-disabled"
                        }`}
                      onClick={() => setDeleteItem(!isDeleteItem)}
                    >
                      <BankIcon title="cta.elimina" height={32} width={32} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={`card-view-container ${isDeleteItem ? "deletedItem" : ""}`}
          >
            <div>
              <span style={{ textTransform: 'initial' }} className="pl-2 bold">{t('button.eliminaBeneficiario')}</span>
            </div>
            <div className="elimina-banner-azioni bold">
              <span onClick={() => actionElimina()}>{t('button.yes')}</span>
              <span onClick={() => setDeleteItem(!isDeleteItem)}>{t('button.no')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  blurText: state.general.blurText
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      setBlurText
    },
    dispatch
  );

export interface iProps {
  id: number
  descrizione?: string
  ibans?: ContattoIbanDto[]
  targhe?: ContattoMezzoDto[]
  altoAdigePass?: ContattoSudtirolPasDto[]
  categorieFiltro?: any
  apriDettaglio: Function
  item: ContattoDto
  additionalInfo?: iAdditionalInfo[]
  mostraDettagli?: string
  dispensaCredenziale?: boolean
  cf?: { cf?: string, dispensaCredenziale: boolean }
  piva?: { piva?: string, dispensaCredenziale: boolean }
  telefono?: ContattoTelefonoDto[]
  ctaVerticali?: boolean
  callbackElimina?: Function
  callbackModifica?: Function
  email?: ContattoEmailDto[]
}

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  iProps;

export default connect(mapStateToProps, mapDispatchToProps)(RubricaCard);
