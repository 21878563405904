import { NotificaMenuDto, ProfiloMenu } from "@sparkasse/commons";
import BankIcon from "component/Icon/icon.component";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  isFirstLevel: boolean;
  items: ProfiloMenu[];
  label: string;
  notificheMenu: NotificaMenuDto[] | undefined;
  location: "sidebar-area" | "topbar-area";
  onClickBack: () => void;
  onClickElement: (el: ProfiloMenu) => void;
}

const SidebarMenuNavigatorArea = (props: Props) => {
  const { t } = useTranslation();

  const {
    isFirstLevel,
    items,
    label,
    notificheMenu,
    location,
    onClickBack,
    onClickElement,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.focus();
    }
  }, [items]);

  return (
    <>
      <div
        className={`menu-window-area ${location}`}
        aria-label={t(label)}
        ref={containerRef}
        tabIndex={-1}
      >
        <div className="menu-window-header">
          <BankIcon
            title="freccia-sinistra"
            className={`frecciaSinistra ${isFirstLevel ? "first-level" : ""}`}
            width={44}
            height={44}
            onClick={onClickBack}
          />
          <span onClick={onClickBack}>{t(label)}</span>
        </div>

        <ul className="menu-window-body ul-submenu">
          {items?.map((el: ProfiloMenu, i: number) => {
            const hasNotification =
              el.id &&
              (notificheMenu?.some((n) => n.id == el.id) ||
                el.submenu?.some((elSubmenu) =>
                  notificheMenu?.some((n) => n.id == elSubmenu.id)
                ));
            return (
              <li
                key={i}
                className="menu-window-element icone-azioni"
                aria-describedby={el.id || ""}
              >
                <a id={el.id || ""} onClick={() => onClickElement(el)}>
                  <div
                    className={`menu-element-icon ${
                      hasNotification ? "has-notification" : ""
                    }`}
                  >
                    <BankIcon title={el.id || "notFound"} active />
                  </div>

                  <div className="menu-element-label" id={el.id || ""}>
                    {el.label === "menu.oneclick"
                      ? t("menu.elenco_oneclick")
                      : t(el.label || "notFound")}
                  </div>

                  {!!el.submenu?.length && el.tipoSubmenu !== "tabMenu" && (
                    <span className="menu-item-corner" />
                  )}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default SidebarMenuNavigatorArea;
