import { annullaLoginMyBank, currentLanguage, Environments, error, getCustomEnv, initI18nAction, loaderActive, loginMyBankType, parametriPrelogin, redirectLoginMyBank, selectedAbi, selectedEnv, setI18nSetCurrentLanguage, setMyBankLoginType, setPreferenzeUtente, urlRedirect } from "@sparkasse/commons";
import BankRadioButton from "component/bank-radio-button/bank-radio-button.component";
import ButtonPrimary from "component/buttonStandard/buttonPrimary.component";
import BankIcon from "component/Icon/icon.component";
import { Item } from "component/inputSelection/inputSelection.component";
import Loader from "component/loader/loader.component";
import { getQueryParams } from "helpers/utilities";
import { setItem } from "index";
import logo from "logoSparkasse.svg";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import Colors from "styles/generated/colors";
import ButtonSecondary from "../../component/buttonStandard/buttonSecondary.component";
import { AppState } from "../../reducers";

const LoginMyBank: React.FC<Props> = (props) => {

  const { t } = useTranslation();
  const [tokenMyBank, setTokenMyBank] = useState<string>()
  const [loginTypes, setLoginTypes] = useState<Item[]>([])

  const {
    setI18nSetCurrentLanguage,
    initI18nAction,
    setPreferenzeUtente,
    setMyBankLoginType,
    redirectLoginMyBank,
    annullaLoginMyBank,
    currentLanguage,
    loginMyBankType,
    parametriPrelogin,
    urlRedirect
  } = props;

  useEffect(() => {
    const token = getQueryParams('token', window.document?.location?.href);
    if (!token) {
      window.open(Environments[getCustomEnv() || selectedEnv].publicSite)
    }
    setTokenMyBank(token);
  }, [])

  const buildTipologieLogin = (parametriTipologie: Array<string>): Item[] => {
    return parametriTipologie.map(item => ({
      key: item.toLowerCase(),
      description: `loginMybank.${selectedAbi}.${item.toLowerCase()}`
    }));
  };

  useEffect(() => {
    if (parametriPrelogin && parametriPrelogin.configurazioni && (parametriPrelogin.configurazioni as { [key: string]: string[] })['MYBANK.OPTION.LOGIN.ENABLED']) {
      const tipologieLogin = (parametriPrelogin.configurazioni as { [key: string]: string[] })['MYBANK.OPTION.LOGIN.ENABLED'];
      if (tipologieLogin.length > 0) {
        setLoginTypes(buildTipologieLogin(tipologieLogin))
      }
    }
  }, [parametriPrelogin])

  const onChangeLanguage = (lingua: string) => {
    initI18nAction();
    setI18nSetCurrentLanguage(lingua.toLowerCase());
    setItem('language', lingua.toLowerCase(), true);
    setPreferenzeUtente();
  };

  const renderLanguageTooltip = (props: any) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {t(`home.navbar.language.${props.language}`)}
      </Tooltip>
    );
  };

  const goToLogin = () => {
    window.open(`${Environments[getCustomEnv() || selectedEnv].corebanking}/api/public/mybank/${loginMyBankType}/redirect?token=${tokenMyBank}`, '_self')
  }


  useEffect(() => {
    if (urlRedirect) {
      window.open(`${urlRedirect.urlRedirect}`, '_self');
    }
  }, [urlRedirect]);

  const goToAnnulla = () => {
    annullaLoginMyBank(tokenMyBank || '')
  }

  return (
    <div >
      {props.loaderActive && <Loader />}
      <div className="main-header">
        <div className="header-logo-search-container">

          <img
            style={{ margin: 8, display: 'inherit' }}
            src={logo}
            alt="logo"
            className={`bank-logo big`}
          />
        </div>
        <div className="header-buttons-container">

          <div style={{ padding: 8 }}>
            <img
              src={"/mybank.jpg"}
              style={{ height: 44 }}
            />
          </div>
          <div className="header-buttons header-buttons-second-group" style={{ display: 'inherit' }}>

            {selectedAbi === '06045' && (
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 250 }}
                overlay={renderLanguageTooltip({
                  language: currentLanguage === "it" ? "de" : "it"
                })}
              >
                <div
                  className="header-change-language"
                  style={{
                    padding: 8,
                  }}
                  onClick={() => onChangeLanguage(currentLanguage === "it" ? "de" : "it")}
                >
                  <BankIcon
                    title={`lingua-${currentLanguage === "it" ? "de" : "it"}`}
                  />
                </div>
              </OverlayTrigger>
            )}
          </div>
        </div>
      </div>
      <div className="main" style={{ height: '100vh' }}>
        <div className="core" style={{ flex: 1, display: 'flex', flexDirection: 'column' }} >
          <div style={{ flex: 1, display: 'flex', alignItems: 'flex-end', alignSelf: 'center' }}>
            <p style={{ fontSize: 20, color: '#000', textAlign: 'center', marginBottom: 32 }}>
              {t('loginMybank.inserireCredenziali')}
            </p>
          </div>
          <div style={{ flex: 1, alignSelf: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'row', color: '#000', justifyContent: 'space-around', marginBottom: 32, marginTop: 16 }}>
              {loginTypes.map((type) => (
                <BankRadioButton textStyle="bold" option={t(type.description)} selected={loginMyBankType == type.key} onPress={() => setMyBankLoginType(type.key)} />
              ))}
            </div>
            <div className="contenitore-pulsanti" style={{ marginTop: 16 }} >
              <div className="pulsanti-sinistra">
                <ButtonSecondary negativeFilled onClick={goToAnnulla} descrizione={t("loginMybank.annulla")} />
              </div>
              <div className="pulsanti-destra">
                <ButtonPrimary filled onClick={goToLogin} descrizione={t("loginMybank.accedi")} disable={!loginMyBankType} />
              </div>
            </div>

          </div>
          <div style={{ flex: 1 }}>
            <p style={{ fontSize: 20, color: '#000', textAlign: 'center', marginBottom: 32 }}>
              {t('loginMybank.perProblemiChiamare')}
            </p>
          </div>
          <div style={{ backgroundColor: Colors.lightgreyVariant, padding: 8 }}>
            <p style={{ fontSize: 14, margin: 0, color: Colors.mediumgrey }}>
              {t("loginMybank.infoBanca")}
            </p>
            <p style={{ fontSize: 14, margin: 0, color: Colors.mediumgrey }} dangerouslySetInnerHTML={{ __html: t("loginMybank.infoCookie") }} />

          </div>
        </div>
      </div>
    </div >
  );
};

const mapStateToProps = (state: AppState) => ({
  error: error(state.general),
  loaderActive: loaderActive(state.general),
  currentLanguage: currentLanguage(state.i18n),
  loginMyBankType: loginMyBankType(state.login),
  parametriPrelogin: parametriPrelogin(state.prelogin),
  urlRedirect: urlRedirect(state.login)
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      setI18nSetCurrentLanguage,
      initI18nAction,
      setPreferenzeUtente,
      setMyBankLoginType,
      annullaLoginMyBank,
      redirectLoginMyBank,
    },
    dispatch
  );

interface RouteParams {
  id: string
}

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<RouteParams>;

export default connect(mapStateToProps, mapDispatchToProps)(LoginMyBank);
