import InputCurrency from "component/inputCurrency/inputCurrency.component";
import InputText from "component/inputText/inputText.component";
import React, { ReactNode, useEffect, useState } from "react";

interface Item {
  key: string;
  description: string;
  hasInputText?: boolean;
  hasInputNumber?: boolean;
  sideElement?: ReactNode;
  complexLabel?: string;
}

interface Props {
  className?: string;
  classNameItemsPrefix?: string;
  descrizione?: string;
  disable?: boolean;
  value?: string;
  items: Array<Item>;
  onChange: (value: string) => void;
  inError?: boolean;
  error?: string;
  checkedElement?: ReactNode;
}

const InputRadioGroup: React.FC<Props> = (props: Props) => {
  const {
    className,
    classNameItemsPrefix,
    descrizione,
    disable,
    value,
    items,
    onChange,
    inError,
    error,
    checkedElement,
  } = props;

  const [isInputSelected, setInputSelected] = useState<boolean>(false);

  const inputId = items.find((i) => i.hasInputNumber || i.hasInputText)?.key;
  const isFormInputSelected =
    !items.find((i) => i.key == value) && value != undefined && value != "";
  const inputValue = isFormInputSelected ? value : "";

  const onInputChange = () => {
    setInputSelected(true);
    onChange("");
  };

  useEffect(() => {
    if (!isFormInputSelected && !!value) {
      setInputSelected(false);
      return;
    }

    setInputSelected(true);
  }, [value]);

  return (
    <>
      <div className={`radio-button-box${className ? " " + className : ""}`}>
        {!!descrizione && (
          <label
            className={`radio-button-label m-0 ${
              error !== "" && inError ? "form-field__label_error" : ""
            }`}
          >
            {descrizione}
          </label>
        )}

        {items.map((item, i) => {
          const isInput = inputId == item.key;
          const isInputChecked =
            item.key === value || (isInput && isInputSelected);

          return (
            <div
              key={"radio_" + i}
              className={`radio-button-row${i === 0 ? " pt-2" : " pt-3"}`}
            >
              <label
                htmlFor={`input-${descrizione}`}
                className={`container-listino m-0${
                  classNameItemsPrefix
                    ? " " + classNameItemsPrefix + item.key
                    : ""
                }`}
              >
                <input
                  type="radio"
                  checked={isInputChecked}
                  onChange={isInput ? onInputChange : () => onChange(item.key)}
                  disabled={disable}
                  id={`input-${descrizione}`}
                  aria-describedby={`input-${descrizione}-error`}
                />

                <span className={`radiobutton-listino`}></span>

                <div
                  className={`radiobutton-listino-description ${
                    !!item.complexLabel ? "maxHeight" : ""
                  }`}
                >
                  <p className="description-listino m-0 font-weight-300">
                    {!!item.complexLabel && isInputChecked ? (
                      <div>
                        {item.description}
                        <div style={{ fontSize: 14 }}>{item.complexLabel}</div>
                      </div>
                    ) : (
                      item.description
                    )}
                  </p>
                </div>
              </label>

              {inputId == item.key && (
                <>
                  {item.hasInputText && (
                    <div className="radio-group-input-text">
                      <InputText
                        noHorizontalPadding
                        descrizione={""}
                        onChange={(v) => onChange(v)}
                        value={inputValue}
                      />
                    </div>
                  )}

                  {item.hasInputNumber && (
                    <div className="radio-group-input-number">
                      <InputCurrency
                        noHorizontalPadding
                        descrizione={""}
                        onChange={(v: string) => onChange(v)}
                        value={inputValue}
                        maxDecimalChar={0}
                      />
                    </div>
                  )}
                </>
              )}

              {item.sideElement}

              {isInputChecked && checkedElement}
            </div>
          );
        })}

        {inError && (
          <div className="mt-3">
            <p
              className="input-radio-error"
              id={`input-${descrizione}-error`}
              role="alert"
            >
              {error}
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default InputRadioGroup;
